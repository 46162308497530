// src/pages/Reservation.js
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FiHome, FiCalendar, FiMapPin } from 'react-icons/fi';
import logo from '../img/menuscan_logo3bg.png';

// Custom icon for Leaflet
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";

const customIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Function to update map center to user's location
function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPosition([latitude, longitude]);
        map.setView([latitude, longitude], 13);
      },
      () => {
        console.error("Unable to retrieve your location");
      }
    );
  }, [map]);

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Popup>Twoja lokalizacja</Popup>
    </Marker>
  );
}

function Reservation() {
  const [locations, setLocations] = useState([
    { id: 1, name: "Restauracja 1", lat: 50.06465, lng: 19.94498 },
    { id: 2, name: "Restauracja 2", lat: 50.05465, lng: 19.93498 },
    { id: 3, name: "Restauracja 3", lat: 50.07465, lng: 19.95498 },
  ]);

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex flex-col">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-gray-800 to-gray-700 shadow-md fixed w-full z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <img src={logo} alt="logo" className="w-48" />
          <nav className="hidden md:flex space-x-4 items-center">
            <a
              href="/"
              className="flex items-center hover:text-green-400 transition-colors duration-300"
            >
              <FiHome className="mr-1" />
              Home
            </a>
            <a
              href="/reservation"
              className="flex items-center hover:text-green-400 transition-colors duration-300"
            >
              <FiCalendar className="mr-1" />
              Rezerwacja
            </a>
          </nav>
        </div>
      </header>

      {/* Spacer to prevent content from being hidden behind fixed header */}
      <div className="mt-20"></div>

      {/* Map Section */}
      <section id="map" className="flex-grow bg-gradient-to-b from-gray-900 to-gray-800">
        <div className="container mx-auto px-4 py-20">
          <h2 className="text-4xl font-extrabold text-center mb-8">Wybierz Lokal</h2>
          <div className="w-full h-96 md:h-[600px]">
            <MapContainer
              center={[50.06465, 19.94498]}
              zoom={13}
              scrollWheelZoom={true}
              className="w-full h-full rounded-lg shadow-lg"
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <LocationMarker />
              {locations.map((location) => (
                <Marker
                  key={location.id}
                  position={[location.lat, location.lng]}
                  icon={customIcon}
                >
                  <Popup>
                    {location.name}
                    <br />
                    <button className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md">
                      Wybierz
                    </button>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-gray-800 to-gray-700">
        <div className="container mx-auto px-4 py-6 text-center">
          <p className="text-gray-400">&copy; {new Date().getFullYear()} MenuScan. Wszelkie prawa zastrzeżone.</p>
        </div>
      </footer>
    </div>
  );
}

export default Reservation;