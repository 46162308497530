// src/pages/PrivacyPolicy.js
import React, { useState, useEffect } from 'react';
import logo from '../img/menuscan_logo3bg.png';
import { FiHome, FiMenu, FiX, FiLogIn } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Api from '../lib/Api';

function PrivacyPolicy() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [privacy, setPrivacy] = useState({
    content: '',
    version: '',
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await Api.getPrivacyPolicy();
        setPrivacy({
          content: response.data.content,
          version: response.data.version,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
        setPrivacy({
          content: '',
          version: '',
          loading: false,
          error: 'Failed to load privacy policy. Please try again later.'
        });
      }
    };

    fetchPrivacyPolicy();
  }, []);

  // Function to parse and format content with proper HTML structure
  const formatContent = (content) => {
    if (!content) return null;

    // Split content by newlines to process each line
    const lines = content.split('\n');
    let formattedContent = [];

    lines.forEach((line, index) => {
      // Format headings (§ sections or numbered headings)
      if (line.startsWith('§') || /^\d+\./.test(line.trim())) {
        formattedContent.push(
          <h3 key={`h-${index}`} className="text-xl md:text-2xl font-semibold mt-6 mb-4">
            {line}
          </h3>
        );
      }
      // Format numbered lists (e.g., 1.1, 2.3, etc.)
      else if (/^\d+\.\d+\./.test(line)) {
        formattedContent.push(
          <p key={`p-${index}`} className="mb-4 text-sm md:text-base">
            {line}
          </p>
        );
      }
      // Format lettered lists (e.g., a), b), etc.)
      else if (/^\s*[a-z]\)/.test(line) && line.trim() !== '') {
        // Don't create a new paragraph, this will be handled as part of a list
        if (formattedContent.length > 0) {
          const lastElement = formattedContent[formattedContent.length - 1];
          if (lastElement.props.className.includes('pl-4')) {
            // Append to existing list
            formattedContent[formattedContent.length - 1] = React.cloneElement(
              lastElement,
              { key: `list-${index}` },
              lastElement.props.children,
              <React.Fragment key={`item-${index}`}>
                {line}<br />
              </React.Fragment>
            );
            return;
          }
        }

        formattedContent.push(
          <p key={`list-${index}`} className="mb-4 pl-4 md:pl-8 text-sm md:text-base">
            {line}<br />
          </p>
        );
      }
      // Empty lines - add some spacing
      else if (line.trim() === '') {
        formattedContent.push(<div key={`space-${index}`} className="h-2"></div>);
      }
      // Regular paragraphs
      else if (line.trim() !== '') {
        formattedContent.push(
          <p key={`text-${index}`} className="mb-4 text-sm md:text-base">
            {line}
          </p>
        );
      }
    });

    return formattedContent;
  };

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex flex-col">
      {/* Header Section */}
      <header className="bg-gray-800 sticky top-0 z-50 shadow-lg">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            {/* Logo on the left */}
            <div className="flex-shrink-0">
              <a href="/">
                <img src={logo} alt="MenuScan" className="h-8 md:h-10" />
              </a>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="/"
                className="flex items-center hover:text-green-400 transition-colors duration-300 text-sm md:text-base"
              >
                <FiHome className="mr-1" />
                Strona Główna
              </a>
              <a
                href="https://app.menuscan.pl"
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
              >
                Zaloguj się
              </a>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="flex items-center p-2 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                aria-label={isMenuOpen ? "Zamknij menu" : "Otwórz menu"}
              >
                {isMenuOpen ?
                  <FiX className="h-5 w-5 text-white" /> :
                  <FiMenu className="h-5 w-5 text-white" />}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div
            className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? 'max-h-[100px] opacity-100 mt-4' : 'max-h-0 opacity-0 mt-0'
              }`}
          >
            <nav className="bg-gray-800 px-4 py-2 rounded-lg">
              <div className="space-y-1">
                <a
                  href="/"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                >
                  <FiHome className="mr-2" /> Strona Główna
                </a>
                <a
                  href="https://app.menuscan.pl"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                >
                  <FiLogIn className="mr-2" /> Zaloguj się
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {/* Main content - Updated styling to match Terms.js */}
      <main className="flex-grow container mx-auto px-4 py-8 md:py-20 mt-2 md:mt-0">
        {privacy.loading ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
          </div>
        ) : privacy.error ? (
          <div className="text-center text-red-600 py-8">
            <p className="text-lg">{privacy.error}</p>
            <button
              onClick={() => window.location.reload()}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            >
              {t('tryAgain')}
            </button>
          </div>
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl font-bold text-center mb-8">
              {t('privacyPolicy')} {privacy.version && `(v${privacy.version})`}
            </h1>
            <div className="privacy-content">
              {formatContent(privacy.content)}
            </div>
          </>
        )}
      </main>

      {/* Footer Section - Matching Terms.js footer */}
      <footer className="bg-gray-800">
        <div className="container mx-auto px-4 md:px-8 py-8 md:py-12">
          <div className="flex flex-col md:flex-row md:flex-wrap">
            <div className="w-full md:w-1/3 mb-8 md:mb-0">
              <img src={logo} alt="MenuScan" className="h-8 mb-4" />
              <p className="text-gray-400 text-sm mb-4">
                Nowoczesny system do obsługi menu i zamówień w Twojej restauracji.
              </p>
              <div className="flex space-x-4">
                <a href="https://facebook.com" className="text-gray-400 hover:text-green-400" aria-label="Facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com" className="text-gray-400 hover:text-green-400" aria-label="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://instagram.com" className="text-gray-400 hover:text-green-400" aria-label="Instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/3 mb-8 md:mb-0">
              <h3 className="text-white font-semibold mb-4">Przydatne linki</h3>
              <ul className="space-y-2">
                <li>
                  <a href="/" className="text-gray-400 hover:text-green-400">Strona główna</a>
                </li>
                <li>
                  <a href="/#app-and-features" className="text-gray-400 hover:text-green-400">Funkcje</a>
                </li>
                <li>
                  <a href="/#contact" className="text-gray-400 hover:text-green-400">Kontakt</a>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-white font-semibold mb-4">Kontakt</h3>
              <ul className="space-y-2 text-gray-400">
                <li>Email: jakub@menuscan.pl</li>
                <li>Tel: +48 793 351 026</li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} MenuScan. Wszelkie prawa zastrzeżone.
            </p>
            <div className="flex space-x-6">
              <a href="/privacy" className="text-gray-400 hover:text-green-400 text-sm">
                Polityka Prywatności
              </a>
              <a href="/terms" className="text-gray-400 hover:text-green-400 text-sm">
                Regulamin
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
