// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import {
  FiMenu, FiX, FiHome, FiInfo, FiMail,
  FiSettings, FiHeadphones, FiShoppingCart, FiTable,
  FiBox, FiCheckCircle, FiUserPlus, FiLogIn,
  FiList, FiBarChart2, FiClock
} from 'react-icons/fi';
import start from '../img/start.png';
import '../App.css';
import products from '../img/products.png';
import settings from '../img/settings.png';
import support from '../img/support.png';
import logo from '../img/menuscan_logo3bg.png';
import historyOrder from '../img/history.png';
import historyOrderMobile from '../img/history-iphonex.png';
import startMobile from '../img/start-iphonex.png';
import productsMobile from '../img/products-iphonex.png';
import settingsMobile from '../img/settings-iphonex.png';
import supportMobile from '../img/support-iphonex.png';
import stats from '../img/stats.png';
import statsMobile from '../img/stats-iphonex.png';
import 'react-device-frameset/styles/marvel-devices.min.css';

// Import useTranslation hook
import { useTranslation } from 'react-i18next';

function Home() {
  // State to detect mobile device
  const [isMobile, setIsMobile] = useState(false);

  // State to handle mobile menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Initialize translation
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || 'pl');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Threshold for mobile devices
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false); // Close menu on desktop
      }
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    // Set different scroll padding based on screen size
    const updateScrollPadding = () => {
      const padding = window.innerWidth < 768 ? '80px' : '60px';
      document.documentElement.style.scrollPaddingTop = padding;
    };

    // Initial setting
    updateScrollPadding();

    // Update on resize
    window.addEventListener('resize', updateScrollPadding);

    return () => {
      // Clean up when component unmounts
      window.removeEventListener('resize', updateScrollPadding);
      document.documentElement.style.scrollPaddingTop = '0';
    };
  }, []);
  // Application features data (titles and descriptions now use translation keys)
  const appFeatures = [
    {
      title: t('appFeatures.orderManagement.title'),
      description: t('appFeatures.orderManagement.description'),
      imageDesktop: start,
      imageMobile: startMobile,
      icon: <FiTable className="text-gradient text-5xl mb-4" />,
    },
    {
      title: t('appFeatures.productManagement.title'),
      description: t('appFeatures.productManagement.description'),
      imageDesktop: products,
      imageMobile: productsMobile,
      icon: <FiBox className="text-gradient text-5xl mb-4" />,
    },
    {
      title: t('appFeatures.statisticsAndSupportSystem.title'),
      description: t('appFeatures.statisticsAndSupportSystem.description'),
      imageDesktop: stats,
      imageMobile: statsMobile,
      icon: <FiBarChart2 className="text-gradient text-5xl mb-4" />,
    },
    {
      title: t('appFeatures.settingsManagement.title'),
      description: t('appFeatures.settingsManagement.description'),
      imageDesktop: settings,
      imageMobile: settingsMobile,
      icon: <FiSettings className="text-gradient text-5xl mb-4" />,
    },
    {
      title: t('appFeatures.supportSystem.title'),
      description: t('appFeatures.supportSystem.description'),
      imageDesktop: support,
      imageMobile: supportMobile,
      icon: <FiHeadphones className="text-gradient text-5xl mb-4" />,
    },
    {
      title: t('appFeatures.orderHistory.title'),
      description: t('appFeatures.orderHistory.description'),
      imageDesktop: historyOrder,
      imageMobile: historyOrderMobile,
      icon: <FiShoppingCart className="text-gradient text-5xl mb-4" />,
    },
  ];

  // Replace your current scrollToSection function with this improved version:

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();

    if (sectionId === 'home') {
      // Scroll to the very top for home
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      // For other sections, find the element and scroll to it with offset
      const element = document.getElementById(sectionId);
      if (element) {
        // Determine header height based on screen size
        // Small offset for desktop, larger for mobile
        const headerHeight = window.innerWidth < 768 ? 400 : 60;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  // Possibilities section data
  const possibilities = [
    {
      title: t('possibilities.intuitiveInterface.title'),
      description: t('possibilities.intuitiveInterface.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
    {
      title: t('possibilities.responsiveness.title'),
      description: t('possibilities.responsiveness.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
    {
      title: t('possibilities.dataSecurity.title'),
      description: t('possibilities.dataSecurity.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
    {
      title: t('possibilities.customNotifications.title'),
      description: t('possibilities.customNotifications.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
    {
      title: t('possibilities.personalization.title'),
      description: t('possibilities.personalization.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
    {
      title: t('possibilities.multilanguageSupport.title'),
      description: t('possibilities.multilanguageSupport.description'),
      icon: <FiCheckCircle className="text-gradient mb-4" />,
    },
  ];

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex flex-col scroll-smooth">
      {/* Header with Logo and Navigation */}
      <header id="home" className="bg-gray-800 py-4 sticky top-0 z-50 shadow-lg">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <div className="flex-shrink-0 flex justify-center md:justify-start">
              <a href="#home"
                onClick={(e) => scrollToSection(e, 'home')}>
                <img src={logo} alt="MenuScan" className="h-10" />
              </a>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-2 md:space-x-4">
              <a
                href="#home"
                onClick={(e) => scrollToSection(e, 'home')}
                className="flex items-center hover:text-green-400 transition-colors duration-300 text-sm md:text-base"
              >
                <FiHome className="mr-1" />
                {t('home')}
              </a>
              <a
                href="#app-and-features"
                onClick={(e) => scrollToSection(e, 'app-and-features')}
                className="flex items-center hover:text-green-400 transition-colors duration-300 text-sm md:text-base"
              >
                <FiInfo className="mr-1" />
                {t('features')}
              </a>
              <a
                href="#possibilities"
                onClick={(e) => scrollToSection(e, 'possibilities')}
                className="flex items-center hover:text-green-400 transition-colors duration-300 text-sm md:text-base"
              >
                <FiBarChart2 className="mr-1" />
                {t('possibilities_name')}
              </a>
              <a
                href="#contact"
                onClick={(e) => scrollToSection(e, 'contact')}
                className="flex items-center hover:text-green-400 transition-colors duration-300 text-sm md:text-base"
              >
                <FiMail className="mr-1" />
                {t('contact')}
              </a>
              {/* Registration and Login Buttons */}
              <a
                href="https://app.menuscan.pl/register"
                className="ml-2 flex items-center px-3 py-1 bg-gradient-to-r from-green-500 to-green-400 text-white rounded-md shadow-lg hover:from-green-600 hover:to-green-500 transition-all duration-300 text-sm md:text-base"
              >
                <FiUserPlus className="mr-1" />
                {t('register')}
              </a>
              <a
                href="https://app.menuscan.pl/"
                className="ml-2 flex items-center px-3 py-1 bg-gradient-to-r from-blue-500 to-blue-400 text-white rounded-md shadow-lg hover:from-blue-600 hover:to-blue-500 transition-all duration-300 text-sm md:text-base"
              >
                <FiLogIn className="mr-1" />
                {t('login')}
              </a>
            </nav>


            {/* Mobile Menu Button */}
            <div className="block md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="flex items-center p-3 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                {isMenuOpen ?
                  <FiX className="h-6 w-6 text-white" /> :
                  <FiMenu className="h-6 w-6 text-white" />}
              </button>
            </div>
          </div>

          {/* Mobile Navigation Menu - Improved Animation */}
          <div
            className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? 'max-h-[350px] opacity-100 mt-4' : 'max-h-0 opacity-0 mt-0'
              }`}
          >
            <nav className="bg-gray-800 px-4 py-2 rounded-lg">

              <div className="space-y-1">
                <a
                  href="#home"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                  onClick={(e) => {
                    scrollToSection(e, 'home')
                    setIsMenuOpen(false)
                  }}
                >
                  <FiHome className="mr-2" /> {t('home')}
                </a>

                <a
                  href="#app-and-features"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                  onClick={(e) => {
                    scrollToSection(e, 'app-and-features')
                    setIsMenuOpen(false)
                  }}
                >
                  <FiInfo className="mr-2" /> {t('features')}
                </a>

                <a
                  href="#possibilities"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                  onClick={(e) => {
                    scrollToSection(e, 'possibilities')
                    setIsMenuOpen(false)
                  }}
                >
                  <FiList className="mr-2" /> {t('possibilities_name')}
                </a>

                <a
                  href="#contact"
                  className="block py-2.5 px-3 rounded-md flex items-center hover:bg-gray-700 hover:text-green-400 transition-colors duration-150"
                  onClick={(e) => {
                    scrollToSection(e, 'contact')
                    setIsMenuOpen(false)
                  }}
                >
                  <FiMail className="mr-2" /> {t('contact')}
                </a>
              </div>

              <div className="pt-3 mt-3 border-t border-gray-700 flex flex-col space-y-2">
                <a
                  href="https://app.menuscan.pl/"
                  className="py-2 px-4 text-center border border-green-500 rounded-md text-sm font-medium hover:bg-green-500 hover:bg-opacity-10 transition-colors duration-150"
                >
                  {t('login')}
                </a>

                <a
                  href="https://app.menuscan.pl/register"
                  className="py-2 px-4 text-center bg-green-500 rounded-md text-sm font-medium hover:bg-green-600 transition-colors duration-150"
                >
                  {t('register')}
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {/* Spacer to prevent content hiding behind the fixed header */}
      <div className="mt-20"></div>
      {/* Hero Section */}
      <section id="home" className="flex-grow bg-gradient-to-b from-gray-900 to-gray-800">
        <div className="container mx-auto px-4 py-20 text-center">
          <h2 className="text-5xl md:text-6xl font-extrabold mb-6 animate-fade-in">
            {t('title')}
          </h2>
          <p className="text-xl md:text-2xl mb-10 animate-fade-in-delay">
            {t('description')}
          </p>
          <a
            href="#contact"
            onClick={(e) => scrollToSection(e, 'contact')}
            className="px-6 py-3 bg-gradient-to-r from-green-500 to-green-400 text-white rounded-md shadow-lg hover:from-green-600 hover:to-green-500 transition-all duration-300 inline-flex items-center justify-center animate-fade-in-delay-2"
          >
            {t('joinUs')}
          </a>
        </div>
      </section>

      {/* Application and Features Section */}
      <section id="app-and-features" className="bg-gray-800 py-16">
        <div className="mx-auto px-4 w-full md:w-[95%]">
          <h3 className="text-4xl font-extrabold text-center mb-12 text-white">{t('appAndFeatures')}</h3>
          <div className="space-y-16">
            {appFeatures.map((appFeature, index) => {
              const isEven = index % 2 === 0;
              return (
                <div
                  key={index}
                  className={`flex flex-col md:flex-row items-center ${isEven ? '' : 'md:flex-row-reverse'
                    } justify-between w-full md:gap-12`}
                >
                  {/* Feature Visual Representation */}
                  <div className="w-full md:w-1/2 flex justify-center mb-8 md:mb-0">
                    <div className="bg-gradient-to-br from-gray-700 to-gray-900 p-6 rounded-xl shadow-xl border border-gray-600 transform transition-all duration-300 hover:scale-105 hover:shadow-2xl w-full max-w-md relative overflow-hidden">
                      {/* Custom decorative elements */}
                      <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-green-500/20 to-transparent rounded-bl-full -mr-10 -mt-10 z-0"></div>
                      <div className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-blue-500/20 to-transparent rounded-tr-full -ml-8 -mb-8 z-0"></div>

                      {/* Feature icon - different for each feature */}
                      <div className="relative z-10">
                        {index === 0 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-green-500 to-teal-400 rounded-lg flex items-center justify-center shadow-lg">
                              <FiList className="text-3xl text-white" />
                            </div>
                            <h4 className="text-xl font-bold text-green-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.orderManagement.tagline')}
                            </div>
                          </div>
                        )}

                        {index === 1 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-blue-500 to-indigo-400 rounded-full flex items-center justify-center shadow-lg">
                              <FiShoppingCart className="text-3xl text-white" />
                            </div>
                            <h4 className="text-xl font-bold text-blue-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.productManagement.tagline')}
                            </div>
                          </div>
                        )}

                        {index === 2 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-purple-500 to-pink-400 rounded-md transform rotate-45 flex items-center justify-center shadow-lg">
                              <FiBarChart2 className="text-3xl text-white transform -rotate-45" />
                            </div>
                            <h4 className="text-xl font-bold text-purple-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.statisticsAndSupportSystem.tagline')}
                            </div>
                          </div>
                        )}

                        {index === 3 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-yellow-500 to-orange-400 hexagon flex items-center justify-center shadow-lg">
                              <FiSettings className="text-3xl text-white" />
                            </div>
                            <h4 className="text-xl font-bold text-yellow-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.settingsManagement.tagline')}
                            </div>
                          </div>
                        )}

                        {index === 4 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-red-500 to-pink-500 diamond flex items-center justify-center shadow-lg">
                              <FiHeadphones className="text-3xl text-white" />
                            </div>
                            <h4 className="text-xl font-bold text-red-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.supportSystem.tagline')}
                            </div>
                          </div>
                        )}

                        {index === 5 && (
                          <div className="flex flex-col items-center">
                            <div className="w-20 h-20 mb-4 bg-gradient-to-r from-cyan-500 to-blue-500 pentagon flex items-center justify-center shadow-lg">
                              <FiClock className="text-3xl text-white" />
                            </div>
                            <h4 className="text-xl font-bold text-cyan-400 text-center mb-3">{appFeature.title}</h4>
                            <div className="text-gray-300 leading-relaxed text-center">
                              {t('appFeatures.orderHistory.tagline')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Feature Description */}
                  <div className="w-full md:w-1/2">
                    <h4 className="text-2xl font-bold text-white mb-4">{appFeature.title}</h4>
                    <p className="text-gray-300 mb-6 leading-relaxed">{appFeature.description}</p>
                    <ul className="space-y-2">
                      {appFeature.bulletPoints?.map((point, idx) => (
                        <li key={idx} className="flex items-start">
                          <span className="text-green-400 mr-2">✓</span>
                          <span className="text-gray-300">{point}</span>
                        </li>
                      ))}
                    </ul>
                    {appFeature.callToAction && (
                      <button className="mt-6 px-4 py-2 bg-gradient-to-r from-green-500 to-green-400 text-white rounded-md hover:from-green-600 hover:to-green-500 transition-all duration-300">
                        {appFeature.callToAction}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Possibilities Section */}
      <section id="possibilities" className="py-16 bg-gray-900">
        <div className="container mx-auto px-4">
          <h3 className="text-4xl font-extrabold text-center mb-12">{t('possibilities_name')}</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {possibilities.map((possibility, index) => (
              <div
                key={index}
                className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col items-center text-center animate-fade-in"
              >
                <div className="mb-4 text-gradient text-4xl">{possibility.icon}</div>
                <h4 className="text-2xl font-semibold mb-2">{possibility.title}</h4>
                <p className="text-gray-300">{possibility.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Promotional Section with Registration and Login Buttons */}
      <section id="contact" className="bg-gradient-to-r from-gray-800 to-gray-700 py-16">
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-4xl font-extrabold mb-6 text-white">{t('joinUs')}</h3>
          <p className="text-lg md:text-xl mb-10 text-gray-300">
            {t('promoDescription')}
          </p>
          <div className="flex justify-center flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
            <a
              href="https://app.menuscan.pl/register"
              className="w-full md:w-auto flex items-center justify-center px-6 py-3 bg-gradient-to-r from-green-500 to-green-400 text-white rounded-md shadow-lg hover:from-green-600 hover:to-green-500 transition-all duration-300"
            >
              <FiUserPlus className="mr-2" />
              {t('registerNow')}
            </a>
            <a
              href="https://app.menuscan.pl/"
              className="w-full md:w-auto flex items-center justify-center px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-400 text-white rounded-md shadow-lg hover:from-blue-600 hover:to-blue-500 transition-all duration-300"
            >
              <FiLogIn className="mr-2" />
              {t('loginNow')}
            </a>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-gray-800 to-gray-700">
        <div className="container mx-auto px-4 py-6 text-center">
          <p className="text-gray-400">&copy; {new Date().getFullYear()} MenuScan. {t('allRightsReserved')}</p>
          <div className="mt-4 flex flex-wrap justify-center items-center gap-x-6 gap-y-2">
            <a href="/privacy" className="hover:text-green-400 transition-colors duration-300">
              {t('privacyPolicy')}
            </a>
            <a href="/terms" className="hover:text-green-400 transition-colors duration-300">
              {t('termsOfService')}
            </a>

            {/* Language Switcher */}
            <div className="flex items-center space-x-2">
              <button
                onClick={() => {
                  i18n.changeLanguage('pl');
                  setLanguage('pl');
                }}
                className={`px-2 py-1 ${language === 'pl' ? 'text-green-400' : 'text-gray-400'} hover:text-green-400`}
                aria-label="Polski"
              >
                PL
              </button>
              <span className="text-gray-500">|</span>
              <button
                onClick={() => {
                  i18n.changeLanguage('en');
                  setLanguage('en');
                }}
                className={`px-2 py-1 ${language === 'en' ? 'text-green-400' : 'text-gray-400'} hover:text-green-400`}
                aria-label="English"
              >
                EN
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;