import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WaitingPage from './pages/waiting_page';
import Home from './pages/home';
import Terms from './pages/terms';
import PrivacyPolicy from './pages/privacy_policy';
import Reservation from './pages/reservation';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />

      </Routes>
    </Router>
  );
}

export default App;
