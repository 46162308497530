export default class Api {
    static api_url = 'https://menuscan.pl/api/v1';
    static x_api_key = 'test';

    // Metoda pomocnicza do ustawiania nagłówków
    static getHeaders(includeAuth = true) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': this.x_api_key,
        };
        if (includeAuth) {
            headers['access_token'] = localStorage.getItem('access_token');
        }
        return headers;
    }

    static async AddWaitingUser(email) {
        const userData = {
            email
        };
        try {
            const response = await fetch(`${this.api_url}/prerelase/user`, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z dodaniem użytkownika:', error);
            throw error;
        }
    }

    // New functions for privacy policy and terms of service

    /**
     * Fetches the privacy policy from the server
     * @returns {Promise<Object>} The privacy policy content
     */
    static async getPrivacyPolicy() {
        try {
            const response = await fetch(`${this.api_url}/privacy-policy`, {
                method: 'GET',
                headers: this.getHeaders(false),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch privacy policy');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching privacy policy:', error);
            throw error;
        }
    }

    /**
     * Fetches the terms of service from the server
     * @returns {Promise<Object>} The terms of service content
     */
    static async getTermsOfService() {
        try {
            const response = await fetch(`${this.api_url}/terms-and-conditions`, {
                method: 'GET',
                headers: this.getHeaders(false),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch terms of service');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching terms of service:', error);
            throw error;
        }
    }
}